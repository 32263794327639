import React from 'react';
import './Category.scss'
import { useNavigationMenu } from '../../utils/useMenuNavigation';

const Category = ({ id, label }) => {

    const navigation = useNavigationMenu();

    return (
        <div className="category mb-2 shadow-sm bg-cover bg-center" 
            onClick={() => { navigation.goCategory(id);}}
            style={{  backgroundImage: `url(${process.env.REACT_APP_FIREBASE_STORAGE_URL}categorias%2F${id}-mini.jpg?alt=media` }}>
                <div className='category-overlay'></div>
                <p>{label}</p>
        </div>
    );
};

export default Category;