import React from 'react';
import { useNavigationMenu } from '../../utils/useMenuNavigation';
import Title from '../title/Title';
import './Cravings.scss'

const Cravings = ({ items }) => {

    const navigation = useNavigationMenu();

    return (
        <div className='mt-8'>
            <Title>¿QUÉ SE TE ANTOJA HOY?</Title>

            <div className='flex overflow-x-auto pb-3 pr-2'>
            {
                items.map((craving, index) => (
                    <div 
                        key={index} 
                        className={`flex flex-shrink-0 w-64 scroll-snap-type rounded-md shadow-md ml-2 relative h-32 overflow-hidden bg-cover bg-center`}
                        onClick={() => { 
                            navigation.goDish(craving.categoryId, craving.id);
                        }}
                        style={{ 
                            // backgroundImage: `url(${process.env.REACT_APP_FIREBASE_STORAGE_URL}${craving.categoryId}%2F${craving.id}-antoja-hoy.jpg?alt=media`
                            backgroundImage: `url(${process.env.REACT_APP_FIREBASE_STORAGE_URL}que-se-te-antoja-hoy%2F${craving.id}-antoja-hoy.jpg?alt=media`
                        }}>
                            <div className='craving-overlay'>
                                <p>{craving.etiqueta}</p>
                                <button>Ver más</button>
                            </div>
                    </div>
                ))
            }
            </div>
        </div>
    );
};

export default Cravings;