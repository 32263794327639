let dataCategories = [];

export const getBranchById = (branchId, data) => {
  const branches = data.sucursales || [];
  return branches.find((b) => b.id === branchId) || null;
};

export const getCategories = (branchId, data = {}) => {
  dataCategories = data?.categories || [];
  return (
    dataCategories.map((c) => {
      return {
        id: c.id,
        etiqueta: c.etiqueta,
        hasComplements: c.permiteComplementos,
      };
    }) || []
  );
};

export const getCategory = (categoryId, data = {}) => {
  return data.categories.find((c) => c.id === categoryId) || null;
  // dataCategories = data.categories || [];
  // const branch = branches.find((b) => b.id === branchId);
  // let category = dataCategories.find((c) => c.id === categoryId) || null;

  // const modifiedDishes = category?.platillos?.map((dish) => {
  //   const modifiedDish = branch?.platillosModificados?.find(
  //     (d) => d.id === dish.id
  //   );

  //   if (!modifiedDish) return dish;

  //   const newDish = Object.assign({}, dish, modifiedDish);
  //   return newDish;
  // });

  // category.platillos = modifiedDishes || category.platillos;
  // return category;
};

export const getDish = (dishId, data = {}) => {
  return data.categories
    .flatMap((c) => c.platillos || [])
    .find((c) => c.id === dishId);
};

export const getTodaysCravings = (branchId = null, data = {}) => {
  const branch = data.branch;
  const dishes = data.categories
    .map(
      (c) =>
        c.platillos
          ?.filter((p) => !branch?.platillosEliminados?.includes(p.id))
          .map((p) => {
            return {
              id: p.id,
              etiqueta: p.etiqueta,
              categoryId: c.id,
            };
          }) || []
    )
    .flat();

  const cravings = branch["antoja-hoy"]?.map((id) => {
    return dishes.find((d) => d.id === id) || [];
  });

  return cravings || [];
};

export const getMostRequested = (branchId, data = {}) => {
  const branch = data.branch || {};
  const dishes = data.categories
    .map(
      (c) =>
        c.platillos?.map((p) => {
          return { ...p, categoryId: c.id };
        }) || []
    )
    .flat();

  const mostRequested = branch["lo-mas-pedido"]
    ?.map((id) => {
      return dishes.find((d) => d.id === id) || [];
    })
    .flat();

  return mostRequested || [];
};
