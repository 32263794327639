import { useLocation, useNavigate, useParams } from "react-router-dom";

export const useNavigationMenu = () => {
    const { branchId, categoryId, dishId } = useParams();
    const location = useLocation();
    const navigation = useNavigate();

    return {
        branchId,
        categoryId,
        dishId,
        goMostRequested: (categoryId) => { navigation(`/${branchId}/menu/lo-mas-pedido/`) },
        goCategory: (categoryId) => { navigation(`/${branchId}/menu/${categoryId}/`) },
        goDish: (categoryId, dishId) => { navigation(`/${branchId}/menu/${categoryId}/${dishId}/`) },
        backHome: () => { navigation(`/${branchId}/menu/`); },
        back: () => { navigation (-1); },
        pathname: location.pathname 
    }
}