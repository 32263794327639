const Video = () => {
  return (
    <div
      className="flex justify-center items-center"
      style={{
        backgroundColor: "black",
        height: "auto",
        color: "white",
        textAlign: "center",
      }}
    >
      <video muted playsInline autoPlay loop style={{ width: "100%" }}>
        <source
          src={`${process.env.REACT_APP_FIREBASE_STORAGE_URL}assets%2Fmenu-video.mp4?alt=media`}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
