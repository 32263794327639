// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB3TfqtbjkCgWK-CGILEEmHkBaXKaWyIV8",
  authDomain: "lpv-digital-menu.firebaseapp.com",
  databaseURL: "https://lpv-digital-menu-default-rtdb.firebaseio.com",
  projectId: "lpv-digital-menu",
  storageBucket: "lpv-digital-menu.appspot.com",
  messagingSenderId: "64296755808",
  appId: "1:64296755808:web:c149884193c5e208771905",
};

// Your web app's Firebase configuration
const firebaseConfigJson = {
  apiKey: "AIzaSyB3TfqtbjkCgWK-CGILEEmHkBaXKaWyIV8",
  authDomain: "lpv-digital-menu-jsons.firebaseapp.com",
  databaseURL: "https://lpv-digital-menu-jsons-default-rtdb.firebaseio.com",
  projectId: "lpv-digital-menu-jsons",
  storageBucket: "lpv-digital-menu-jsons.appspot.com",
  messagingSenderId: "97302368291",
  appId: "1:97302368291:web:be1067a21ded74b52aa196",
};

export const app = initializeApp(firebaseConfig);

export const appJson = initializeApp(firebaseConfigJson, "menu");

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6Lf_GMApAAAAAIl3g6_1y46OVRybAair7Sj54bPu"),
  isTokenAutoRefreshEnabled: true,
});

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// const appCheckJson = initializeAppCheck(appJson, {
//   provider: new ReCaptchaV3Provider("6Lf_GMApAAAAAIl3g6_1y46OVRybAair7Sj54bPu"),
//   isTokenAutoRefreshEnabled: true,
// });

const db = getFirestore(app);

export const dbJson = getFirestore(appJson);

export default db;
