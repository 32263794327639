import React, { useCallback, useEffect, useRef, useState } from "react";
import db from "../../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

const MenuJson = () => {
  const branchId = document.location.pathname
    .split("/")
    .filter((element) => element)[0];
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const dataFetched = useRef(false);

  const fetchData = useCallback(async () => {
    if (!loading || dataFetched.current) return;

    //Categories
    const categoriesQuerySnapshot = await getDocs(collection(db, "menu"));
    const categories = categoriesQuerySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .sort((a, b) => a.orden - b.orden);

    //Dishes
    const dishesQuerySnapshot = await getDocs(collection(db, "platillos"));
    const dishes = dishesQuerySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .sort((a, b) => a.orden - b.orden);

    //Extras and sides
    const extrasQuerySnapshot = await getDocs(
      collection(db, "complementos-y-extras")
    );
    const extras = extrasQuerySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .sort((a, b) => a.orden - b.orden);

    //Create the structure
    for (var i = 0; i <= 8; i++) {
      categories[i].platillos = dishes.filter(
        (d) => d.categoria === categories[i].id
      );
    }
    categories[9].complementos = extras.filter(
      (e) => e.categoria === "complementos"
    );
    categories[9].extras = extras.filter((e) => e.categoria === "extras");

    //Branch
    const branchesQuerySnapshot = await getDocs(collection(db, "sucursales"));
    const branch = branchesQuerySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .find((branch) => branch.id === branchId);

    //Dishes changes
    const menuChangesQuerySnapshot = await getDocs(
      collection(db, "sucursales/" + branchId + "/cambios-platillos")
    );
    const dishesChanges = menuChangesQuerySnapshot.docs.map((dish) => ({
      id: dish.id,
      ...dish.data(),
    }));

    //Final data structure
    const newCategories = categories.map((category) => {
      if (category.id === "complementos-y-extras") {
        return category;
      }
      const modifiedDishes = category.platillos
        .map((dish) => {
          const modifiedDish = dishesChanges.find((d) => d.id === dish.id);
          if (modifiedDish) Object.assign(dish, modifiedDish);
          return dish;
        })
        .filter((dish) => !dish.eliminado);

      return { ...category, platillos: modifiedDishes };
    });

    setData({
      categories: newCategories,
      branch,
    });

    setLoading(false);
    dataFetched.current = true;
  }, [loading, branchId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  console.log("DataSource: ", JSON.stringify(data));
  return (
    <div style={{ fontFamily: "Arial", fontSize: "12px" }}>
      {JSON.stringify(data)}
    </div>
  );
};

export default MenuJson;
