import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import LogoSVG from "../../resources/img/logo.png";
import splashTop from "../../resources/img/splash-top.png";
import "./Home.scss";

const Home = () => {
  const { branchId } = useParams();
  const navigate = useNavigate();

  return (
    <div
      className="splash-bg"
      style={{
        backgroundImage: `url(${process.env.REACT_APP_FIREBASE_STORAGE_URL}assets%2Fsplash.jpg?alt=media`,
      }}
    >
      <div className="overlay flex justify-center items-center">
        <div className="flex flex-col h-full w-full justify-center items-center">
          <img
            src={splashTop}
            alt="Top decoration"
            className="absolute top-0 left-0 w-full"
          />
          <img src={LogoSVG} alt="Logo LPV" className="mb-20" width={172}></img>
          <h1 className="w-full text-center mb-4">¡Bienvenido al sabor!</h1>
          <h2 className="w-full text-center">
            Descubre el típico antojo
            <br></br>
            mexicano, <b>a lo jarocho</b>
          </h2>
          <button
            className="mt-10"
            style={{
              background: "white",
              padding: "12px 32px 12px 32px",
              borderRadius: "25px",
              width: "172px",
              height: "48px",
              lineHeight: "24px",
              color: "#FC540C",
              fontWeight: "700",
              fontSize: "18px",
              fontFamily: "Barlow",
            }}
            onClick={() => {
              navigate(`/${branchId}/menu`);
            }}
          >
            Abrir menú
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
