import React from "react";

const ErrorBranch = () => {
  return (
    <div className="relative">
      <h3>Error, no existe la sucursal.</h3>
    </div>
  );
};

export default ErrorBranch;
