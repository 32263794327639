import React, { useCallback, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import MenuPage from "./pages/menu/MenuPage";
import CategoryPage from "./pages/category/CategoryPage";
import DishPage from "./pages/dish/DishPage";
import MostRequestedPage from "./pages/most-requested/MostRequestedPage";
import SidesAndExtrasPage from "./pages/sides-and-extras/SidesAndExtrasPage";
import "./App.css";
import ErrorBranch from "./pages/error-branch/ErrorBranch";
import FirebaseDataContext from "./firebaseDataContext";
// import { dataSource } from "./utils/dataSource";
import MenuJson from "./pages/menujson/MenuJson";
import db, { dbJson } from "./firebaseConfig";
import { addDoc, collection, doc, getDocs, setDoc } from "firebase/firestore";

function App() {
  const branchId = document.location.pathname
    .split("/")
    .filter((element) => element)[0];
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const dataFetched = useRef(false);

  const fetchData = useCallback(async () => {
    if (!loading || dataFetched.current) return;

    //Menu
    const menuQuerySnapshot = await getDocs(collection(dbJson, branchId));
    const menu = menuQuerySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setData(JSON.parse(menu[0].json));

    setLoading(false);
    dataFetched.current = true;
  }, [loading, branchId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  console.log();
  return (
    <FirebaseDataContext.Provider value={data}>
      <Router>
        <div className="app">
          <Routes>
            <Route path="/:branchId" element={<Home />} />
            <Route path="/:branchId/menu" element={<MenuPage />} />
            <Route
              path="/:branchId/menu/:categoryId"
              element={<CategoryPage />}
            />
            <Route
              path="/:branchId/menu/:categoryId/:dishId"
              element={<DishPage />}
            />
            <Route
              path="/:branchId/menu/lo-mas-pedido"
              element={<MostRequestedPage />}
            />
            <Route
              path="/:branchId/menu/complementos-y-extras"
              element={<SidesAndExtrasPage />}
            />
            <Route path="/error-branch" element={<ErrorBranch />} />
            <Route path="/:branchId/menu/json" element={<MenuJson />} />
          </Routes>
        </div>
        <div className="only-mobile">
          <div
            style={{
              border: "solid 3px white",
              borderRadius: 100,
              padding: 10,
              marginBottom: 15,
              marginTop: -45,
            }}
          >
            <img src="/logo128.png" style={{ width: 32 }} alt="Logo LPV"></img>
          </div>
          <div className="text-center" style={{ maxWidth: "420px" }}>
            Para una óptima experiencia, el menú solo puede ser visualizado en
            un móvil en posición vertical (retrato).
          </div>
        </div>
      </Router>
    </FirebaseDataContext.Provider>
  );
}

export default App;

// async function addDataToBranch(allData, branch) {
//   const platillos = allData.categories
//     .flatMap((category) => {
//       return category.platillos;
//     })
//     .filter((dish) => {
//       return !!dish;
//     })
//     .map((dish) => {
//       return {
//         id: dish.id,
//         precioMin: dish.precioMin || null,
//         precioMax: dish.precioMax || null,
//         opciones:
//           dish.opciones && dish.opciones.length > 0 ? dish.opciones : null,
//       };
//     });

//   for (let platillo of platillos) {
//     const docRef = doc(
//       db,
//       `sucursales/${branch}/cambios-platillos`,
//       platillo.id
//     );
//     await setDoc(docRef, platillo);
//   }
// }

// addDataToBranch(data, "campeche");
// addDataToBranch(data, "cdmx");
// addDataToBranch(data, "coatepec");
// addDataToBranch(data, "coatzacoalcos");
// addDataToBranch(data, "framboyanes");
// addDataToBranch(data, "merida");
// addDataToBranch(data, "meridacaucel");
// addDataToBranch(data, "orizaba");
// addDataToBranch(data, "puebla");
// addDataToBranch(data, "queretaro");
// addDataToBranch(data, "regional");
// addDataToBranch(data, "tuxpan");
// addDataToBranch(data, "villahermosa");
// addDataToBranch(data, "xalapa");
