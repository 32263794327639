import React from 'react';
import './Title.scss'

const Title = ({ children, className, ...otherProps }) => {

  return (
    <div {...otherProps} className={`title mb-3 ml-2 ${className}`}>
        {children}
    </div>
  );
};

export default Title;