import React, { useContext } from "react";
import { getCategories, getTodaysCravings } from "../../utils/data";

import Category from "../../components/category/Category";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Title from "../../components/title/Title";
import Video from "../../components/video/Video";
import Cravings from "../../components/cravings/Cravings";

import "./MenuPage.scss";
import MostRequested from "../../components/most-requested/MostRequested";
import { useParams } from "react-router-dom";
import FirebaseDataContext from "../../firebaseDataContext";

const Menu = () => {
  const firebaseData = useContext(FirebaseDataContext);
  const { branchId } = useParams();
  const categories = getCategories(branchId, firebaseData);
  const todaysCravings = getTodaysCravings(branchId, firebaseData);
  return (
    <div className="relative menu-page">
      <Header></Header>
      <Video></Video>
      <Cravings items={todaysCravings}></Cravings>

      <div className="mt-8 mb-16">
        <Title>PARA TODOS LOS GUSTOS</Title>

        <div className="flex flex-wrap pl-2 pr-2">
          {categories.map((category, index) => (
            <div key={index} className="w-1/2 odd:pr-1 even:pl-1">
              <Category
                key={index}
                id={category.id}
                label={category.etiqueta}
              />
            </div>
          ))}
        </div>
      </div>

      <MostRequested></MostRequested>
      <Footer></Footer>
    </div>
  );
};

export default Menu;
