import React from "react";
import "./Header.scss";
import LogoSVG from "../../resources/img/logo.png";
import HomeSVG from "../../resources/img/home.svg";
import ArrowSVG from "../../resources/img/arrow.svg";
import headerLeft from "../../resources/img/header-left.png";
import headerRight from "../../resources/img/header-right.png";
import { useNavigate, useParams } from "react-router-dom";

const Header = ({ title }) => {
  const { branchId } = useParams();

  // const branch = getBranchById(branchId);
  const navigate = useNavigate();

  // if (!isValidBranch(branchId)) {
  //   return <Navigate to={"/error-branch"}></Navigate>;
  // }

  return (
    <div className="header flex justify-center items-center">
      {title ? (
        <div>
          <div>
            <span
              className="decoration-left"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={ArrowSVG} alt="Back"></img>
            </span>
          </div>
          <div className="logo">
            <h1>{title}</h1>
          </div>
          <div>
            <span
              className="decoration-right"
              onClick={() => {
                navigate(`/${branchId}/menu`);
              }}
            >
              <img src={HomeSVG} alt="Home"></img>
            </span>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <img
              src={headerLeft}
              alt="Left decoration"
              className="decoration-left"
            />
          </div>
          <div className="logo">
            <img src={LogoSVG} alt="Main Logo" />
          </div>
          <div>
            <img
              src={headerRight}
              alt="Right decoration"
              className="decoration-right"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
