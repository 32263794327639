import React, { useContext, useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Dish from "../../components/dish/Dish";
import "./MostRequestedPage.scss";
import { getMostRequested } from "../../utils/data";
import { useParams } from "react-router-dom";
import FirebaseDataContext from "../../firebaseDataContext";

const MostRequestedPage = () => {
  const { branchId } = useParams();
  const firebaseData = useContext(FirebaseDataContext);
  const mostRequestedDishes = getMostRequested(branchId, firebaseData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="relative menu-page">
      <Header title={"Los más pedido"}></Header>

      <div
        className="category-cover flex justify-center items-center bg-cover bg-center w-full"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_FIREBASE_STORAGE_URL}categorias%2Flo-mas-pedido-full.jpg?alt=media)`,
          height: "185px",
        }}
      ></div>

      <div className="dishes-list my-7">
        {mostRequestedDishes.map((dish, index) => (
          <Dish key={index} item={dish} className="mb-3"></Dish>
        ))}
      </div>

      <Footer></Footer>
    </div>
  );
};

export default MostRequestedPage;
