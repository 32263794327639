import React, { useContext, useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Title from "../../components/title/Title";
import { getCategory, getDish } from "../../utils/data";
import { useNavigationMenu } from "../../utils/useMenuNavigation";
import "./DishPage.scss";
import FirebaseDataContext from "../../firebaseDataContext";

const DishPage = () => {
  const firebaseData = useContext(FirebaseDataContext);
  const navigation = useNavigationMenu();
  const hasComplements = getCategory(
    navigation.categoryId,
    firebaseData
  )?.permiteComplementos;

  const dish = getDish(navigation.dishId, firebaseData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!dish) {
    return <div>El platillo no existe</div>;
  }

  return (
    <div className="relative menu-page dish-page">
      <Header title={dish.etiqueta}></Header>

      <div
        className="flex justify-center items-center bg-cover bg-center w-full"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_FIREBASE_STORAGE_URL}${navigation.categoryId}%2F${navigation.dishId}-full.jpg?alt=media`,
          height: "360px",
        }}
      ></div>
      <div className="px-5 -mt-6 mb-8">
        <div className="shadow-md bg-white rounded-md py-5 px-5 pb-7">
          <div className="flex">
            <Title
              className={`flex-shirnk-0`}
              style={{ marginLeft: 0, paddingRight: "3px" }}
            >
              {dish.etiqueta}
            </Title>
            <div
              style={{
                fontFamily: "Barlow Condensed",
                fontSize: "22px",
                fontWeight: "300",
                marginTop: "-3px",
              }}
              className={`flex-1 text-right`}
            >
              ${dish.precioMin} {dish.precioMax ? ` A $${dish.precioMax}` : ``}
            </div>
          </div>

          <div className="description">
            {dish.descripcion.split("\\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>

          {dish.elige && (
            <div className="choose" style={{ whiteSpace: "pre-line" }}>
              <hr></hr>
              <p>Elige:</p>
              {dish.elige.split("\\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          )}

          {dish.opciones?.length > 0 && (
            <div className="options">
              <hr></hr>
              {dish.opciones.map((o, idx) => {
                return (
                  <div key={idx} className="flex mb-1">
                    <div className="flex-shrink-0">{o.etiqueta}</div>
                    <div className="flex-1 text-right">${o.precio}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {hasComplements && (
        <div className="text-center">
          <Title
            className={"w-full"}
            style={{
              fontSize: "16.5px",
              margin: "0 0 16px 0",
            }}
          >
            Personaliza el platillo junto a tu mesero
          </Title>

          <button
            style={{
              backgroundColor: "#FCB90C",
              color: "#393939",
              borderRadius: "100px",
              fontSize: "15px",
              fontFamily: "Barlow",
              letterSpacing: "0.5px",
              fontWeight: 600,
              padding: "8px 18px 8px 20px",
              marginBottom: "42px",
            }}
            onClick={() => {
              navigation.goCategory("complementos-y-extras");
            }}
          >
            Complementos y extras
          </button>
        </div>
      )}

      <Footer></Footer>
    </div>
  );
};

export default DishPage;
