import React from "react";
import "./Dish.scss";
import { useParams } from "react-router-dom";
import { useNavigationMenu } from "../../utils/useMenuNavigation";

const Dish = ({ item, className }) => {
  const { id, etiqueta, precioMin, precioMax } = item;
  const navigation = useNavigationMenu();
  const { categoryId } = useParams();
  return (
    <div
      className={`flex flex-shrink-0 w-64 scroll-snap-type rounded-md shadow-md ml-2 ${className}`}
      onClick={() => {
        navigation.goDish(item.categoryId || categoryId, item.id);
      }}
      style={{
        backgroundColor: "white",
        width: "300px",
        height: "128px",
        borderRadius: "4px",
        overflow: "hidden",
        border: "solid 1px rgb(193, 193, 193, 0.26)",
      }}
    >
      <div
        style={{
          width: "180px",
          backgroundImage: `url(${process.env.REACT_APP_FIREBASE_STORAGE_URL}${
            item.categoryId || categoryId
          }%2F${id}-mini.jpg?alt=media`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>

      <div className="flex-1 px-1 pt-5 relative">
        <p
          style={{
            fontFamily: "Barlow",
            fontWeight: "600",
            fontSize: "15px",
            lineHeight: "18px",
            marginBottom: "5px",
            color: "#393939",
          }}
        >
          {etiqueta}
        </p>

        <span
          style={{
            fontFamily: "Barlow",
            fontWeight: "400",
            fontSize: "15px",
            color: "#393939",
          }}
        >
          ${precioMin}
        </span>

        {precioMax && (
          <span
            style={{
              fontFamily: "Barlow",
              fontWeight: "400",
              fontSize: "15px",
              color: "#393939",
            }}
          >
            &nbsp;a ${precioMax}
          </span>
        )}

        <div
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "5px",
            color: "#393939",
            border: "solid 1px #393939",
            textAlign: "center",
            fontSize: "13px",
            fontWeight: "500",
            position: "absolute",
            right: 8,
            bottom: 8,
          }}
        >
          !
        </div>
      </div>
    </div>
  );
};

export default Dish;
