import React, { useContext, useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Dish from "../../components/dish/Dish";
import "./CategoryPage.scss";
import { getBranchById, getCategory } from "../../utils/data";
import MostRequested from "../../components/most-requested/MostRequested";
import { useNavigationMenu } from "../../utils/useMenuNavigation";
import { useParams } from "react-router-dom";
import FirebaseDataContext from "../../firebaseDataContext";

const groupDishes = (category, branchId, data) => {
  const branch = getBranchById(branchId, data);

  //Group dishes by 'grupo' property
  const groupedArrays = {};
  category.platillos.forEach((platillo) => {
    const groupName = platillo.grupo || platillo.id;
    if (!groupedArrays[groupName]) groupedArrays[groupName] = [];
    groupedArrays[groupName].push(platillo);
  });
  const resultArray = Object.values(groupedArrays);

  return resultArray || [];
};

const CategoryPage = () => {
  const firebaseData = useContext(FirebaseDataContext);
  const navigation = useNavigationMenu();
  const { branchId } = useParams();
  const category = getCategory(navigation.categoryId, firebaseData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!category) {
    return <div>ERROR: Categoría no encontrada</div>;
  }

  const groupedDishes = groupDishes(category, branchId, firebaseData);

  return (
    <div className="relative menu-page">
      <Header title={category.etiqueta}></Header>

      <div
        className="flex justify-center items-center bg-cover bg-center w-full"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_FIREBASE_STORAGE_URL}categorias%2F${category.id}-full.jpg?alt=media`,
          height: "185px",
        }}
      ></div>

      <div className="dishes-list my-7">
        {groupedDishes.map((group, index) => {
          return (
            <div className="flex overflow-x-auto pb-2 pr-2" key={index}>
              {group.map((dish, index) => (
                <Dish key={index} item={dish}></Dish>
              ))}
            </div>
          );
        })}
      </div>

      <MostRequested></MostRequested>
      <Footer></Footer>
    </div>
  );
};

export default CategoryPage;
