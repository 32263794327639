import React, { useContext, useEffect } from "react";
import Header from "../../components/header/Header";
import "./SidesAndExtrasPage.scss";
import Title from "../../components/title/Title";
import Footer from "../../components/footer/Footer";
import SidesSVG from "../../resources/img/sides.svg";
import ExtrasSVG from "../../resources/img/extras.svg";
import FirebaseDataContext from "../../firebaseDataContext";

const SidesAndExtrasPage = () => {
  const firebaseData = useContext(FirebaseDataContext);
  const data = firebaseData.categories.find(
    (c) => c.id === "complementos-y-extras"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="relative menu-page addons-page">
      <Header title={"Complementos y Extras"}></Header>

      <div className="sub-header">
        Disponibles solo para complementar platillos del menu principal.
      </div>

      <div className="p-3">
        <div className="shadow-md bg-white rounded-md py-5 px-5 pb-7 mb-3">
          <img src={SidesSVG} alt="Sides"></img>
          <Title style={{ marginLeft: 0, marginTop: 6, marginBottom: 16 }}>
            Complementos
          </Title>
          {data.complementos.map((c, idx) => {
            return (
              <div key={idx} className="label">
                {c.etiqueta}
              </div>
            );
          })}
        </div>

        <div className="shadow-md bg-white rounded-md py-5 px-5 pb-7 mb-3">
          <img src={ExtrasSVG} alt="Extras"></img>
          <Title style={{ marginLeft: 0, marginTop: 6, marginBottom: 16 }}>
            Extras
          </Title>
          {data.extras.map((e, idx) => {
            return (
              <div key={idx} className="flex mb-1 extra">
                <div className="flex-shrink-0">{e.etiqueta}</div>
                <div className="flex-1 text-right">${e.precio}</div>
              </div>
            );
          })}
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default SidesAndExtrasPage;
