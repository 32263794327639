import React from 'react';
import { useNavigationMenu } from '../../utils/useMenuNavigation';
import './MostRequested.scss'

const MostRequested = () => {

    const navigation = useNavigationMenu();

    return (
        <div className='most-requested flex justify-center items-center'>
          <button onClick={() => {
            navigation.goMostRequested();
          }}>
            Ver más
          </button>
        </div>
    );
};

export default MostRequested;