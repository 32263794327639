import React, { useContext } from "react";
import LogoSVG from "../../resources/img/logo.png";
import footerLeft from "../../resources/img/footer-left.png";
import footerRight from "../../resources/img/footer-right.png";
import "./Footer.scss";
import FirebaseDataContext from "../../firebaseDataContext";

const Footer = () => {
  const firebaseData = useContext(FirebaseDataContext);

  return (
    <div className="footer flex justify-center items-center gap-12">
      <div className="decoration-left">
        <img src={footerLeft} alt="Main Logo" />
      </div>

      <div className="logo">
        <img src={LogoSVG} alt="Main Logo" />
      </div>

      <div className="logo">
        <p>
          Nos
          <br></br>
          encanta
          <br></br>
          complacerte
        </p>
      </div>
      <div className="decoration-right">
        <img src={footerRight} alt="Main Logo" />
      </div>
      <label
        style={{
          position: "absolute",
          bottom: "10px",
          fontSize: "12px",
          lineHeight: "12px",
          textAlign: "center",
          width: "calc(100% - 30px)",
        }}
      >
        Suc. {firebaseData.branch.etiqueta}
      </label>
    </div>
  );
};

export default Footer;
